export const roles = [
  {
    role: 'Company User',
    access: [
      {
        verb: 'write',
        scopes: [
          'taskboard',
          'dashboard',
          'style',
          'item',
          'report',
          'analytics',
          'marketing',
          'orders',
          'compliance',
          'example',
        ],
      },
      {
        verb: 'read',
        scopes: [
          'taskboard',
          'dashboard',
          'style',
          'item',
          'report',
          'analytics',
          'marketing',
          'orders',
          'compliance',
          'example',
        ],
      },
    ],
  },
  {
    role: 'Company Admin',
    access: [
      {
        verb: 'write',
        scopes: ['admin'],
      },
      {
        verb: 'read',
        scopes: ['admin'],
      },
    ],
  },
  {
    role: 'Test User',
    access: [
      {
        verb: 'write',
        scopes: [],
      },
      {
        verb: 'read',
        scopes: ['taskboard', 'dashboard', 'style/workflow', 'storybook', 'test'],
      },
    ],
  },
  {
    role: 'Test Admin',
    access: [
      {
        verb: 'write',
        scopes: ['taskboard', 'dashboard', 'admin', 'style', 'style/workflow', 'storybook', 'test'],
      },
      {
        verb: 'read',
        scopes: ['taskboard', 'dashboard', 'admin', 'style', 'style/workflow', 'storybook', 'test'],
      },
    ],
  },

  {
    role: 'Supplier Admin',
    access: [
      {
        verb: 'read',
        scopes: ['taskboard', 'dashboard', 'admin', 'style', 'style/workflow', 'storybook', 'test'],
      },
    ],
  },
];
