import { createTheme } from '@mui/material';
import { palette } from './palette';
import { typography } from './typography';
import { breakpoints } from './breakpoints';
import { components } from './components';

const spacing = {
  none: 0,
  xxxs: 2,
  xxs: 4,
  xs: 8,
  sm: 12,
  md: 16,
  lg: 24,
  xl: 36,
  xxl: 48,
  xxxl: 64,
};

export const styledTheme = {
  palette,
  typography,
  spacing,
  breakpoints,
  components,
};

export const theme = createTheme({
  palette,
  typography,
  spacing: Object.values(spacing),
  breakpoints,
  components,
});
