import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { UseMutationOptions, UndefinedInitialDataOptions } from '@tanstack/react-query';
import { useCheckAccess } from 'features/accessControl';
import type { ApiResponse } from 'apisauce';
import { t } from '@lingui/macro';
import type { PaginateResponse } from 'types';
import { api, handleRequest } from '../utils';
import { DefaultError } from '@tanstack/query-core';
import { stringParse } from 'utils';
import { set } from 'lodash/fp';
import { size, forEach } from 'lodash';

const noAccessData = {
  Error: t`No Access`,
  Message: t`You do not have write access to the requested page.`,
};
const noAccessPromise = () =>
  Promise.resolve({
    ok: true,
    data: noAccessData,
  } as ApiResponse<typeof noAccessData>);

/**
 * @param url - The url to fetch data from
 * @param key - queryKey forwarded to useQuery
 * @param revalidate - By default we cache data, setting this to true will always refetch the data
 * @param options - Query options forwarded to useQuery
 * @returns a useQuery hook
 */
export function useGet<T>(
  url: string,
  key: string | string[],
  revalidate: boolean = true,
  options: Omit<UndefinedInitialDataOptions<T>, 'queryKey'> = {}
) {
  return useQuery<T>({
    ...options,
    queryKey: typeof key === 'string' ? [key] : key,
    queryFn: () => handleRequest(api.get(url)),
    ...(revalidate ? { staleTime: 0 } : {}),
  });
}

type PageState = {
  index: number;
  cursors: string[];
};

/**
 * @param url - The url to fetch data from
 * @param key - queryKey array forwarded to useQuery
 * @param revalidate - By default we don't cache data, setting this to false will cache data
 * @param query - object of url query parameters, will be used to build the query string to first page, subsequent pages will only use cursor
 * @param options - Query options forwarded to useQuery
 * @returns an extended useQuery hook with pagination
 */
export function usePagedGet<T>(
  url: string,
  key: string[],
  query: string = '',
  revalidate: boolean = false,
  options: Omit<UndefinedInitialDataOptions<PaginateResponse<T>>, 'queryKey'> = {}
) {
  const [page, setPage] = useState<PageState>({ index: 0, cursors: [''] });
  const pagedQuery = useQuery<PaginateResponse<T>>({
    ...options,
    queryKey: [...key, page.cursors[page.index]],
    queryFn: () =>
      handleRequest(
        api.get(`${url}?${page.cursors[page.index] ? `cursor=${page.cursors[page.index]}` : query}`)
      ),
    ...(revalidate ? { staleTime: 0 } : {}),
    refetchOnMount: 'always',
  });
  const nextPage = (cursor: string) => {
    setPage(prev => ({ index: prev.index + 1, cursors: [...prev.cursors, cursor] }));
  };
  const previousPage = () => {
    setPage(prev => ({ index: prev.index - 1, cursors: prev.cursors }));
  };
  const changePage = (statePageIndex: number) => {
    if (statePageIndex > page.index) {
      nextPage(pagedQuery.data?.pagination.cursor || '');
    }
    if (statePageIndex < page.index) {
      previousPage();
    }
  };
  return {
    ...pagedQuery,
    data: pagedQuery.data?.data || [],
    pagination: pagedQuery.data?.pagination || { hasMore: false, cursor: '' },
    pageIndex: page.index,
    pageSize: 100,
    nextPage,
    previousPage,
    changePage,
    queryKey: [...key, page.cursors[page.index]],
  };
}

/**
 * @param url - The url to post data to
 * @param options - Mutation options forwarded to useMutation
 * @returns a useMutation hook
 */
export function usePost<T>(
  url: string,
  options: UseMutationOptions<unknown, DefaultError, T, unknown> = {}
) {
  const hasAccess = useCheckAccess(true);
  return useMutation<unknown, DefaultError, T, unknown>({
    ...options,
    mutationFn: hasAccess && url ? data => api.post<T>(url, data) : () => noAccessPromise(),
  });
}

type Put<T> = {
  data: Partial<T> | Partial<T>[];
  tableUpdate?: Record<string, unknown>;
  index?: number;
  rows?: number[];
  urlParams?: string[];
  queryKey?: string[];
};

type Context<T> = {
  prevData: PaginateResponse<T>;
  newData: PaginateResponse<T>;
  queryKey: string[];
};

/**
 * @param url - The url to post data to
 * @param updateCache - optimistically update the cache with the new data, requires columnId, value, index, queryKey in mutate function
 * @param options - Mutation options forwarded to useMutation
 * @returns a useMutation hook
 */
export function usePut<T, C = T>(
  url: string,
  updateCache: boolean = false,
  options: UseMutationOptions<unknown, DefaultError, Put<T>, unknown> = {}
) {
  const queryClient = useQueryClient();
  const hasAccess = useCheckAccess(true);
  let cacheUpdater: Partial<UseMutationOptions<unknown, DefaultError, Put<T>, unknown>> = {};
  if (updateCache) {
    cacheUpdater = {
      onMutate: ({ tableUpdate, index, rows, queryKey }) => {
        if (!queryKey) {
          return;
        }
        const prevData = queryClient.getQueryData(queryKey) as PaginateResponse<C>;
        const newData = {
          ...prevData,
          data: prevData.data.map((obj: C, objIndex: number) => {
            if ((objIndex === index || rows?.includes(objIndex)) && size(tableUpdate) > 0) {
              let newObj = { ...obj } as object;
              forEach(tableUpdate, (value, columnId) => {
                newObj = set(columnId, value, newObj);
              });
              return newObj;
            }
            return obj;
          }),
        };
        queryClient.setQueryData(Array.isArray(queryKey) ? queryKey : [queryKey], newData);
        return { newData, prevData, queryKey };
      },
      onError: (err, newData, context) => {
        const ctx = context as Context<C>;
        queryClient.setQueryData(ctx.queryKey, ctx.prevData);
      },
    };
  }
  return useMutation<unknown, DefaultError, Put<T>, unknown>({
    ...options,
    mutationFn:
      hasAccess && url
        ? props => {
            const apiUrl = props.urlParams ? stringParse(url, props.urlParams) : url;
            return handleRequest(api.put<T>(apiUrl, props.data));
          }
        : () => noAccessPromise(),
    ...cacheUpdater,
  });
}

/**
 * @param url - The url to post data to
 * @param options - Mutation options forwarded to useMutation
 * @returns a useMutation hook
 */
export const useDelete = (
  url: string,
  options: UseMutationOptions<
    ApiResponse<unknown>,
    Error,
    string | { id: string }[] | undefined,
    unknown
  > = {},
  isBatchDelete: boolean = false
) => {
  const hasAccess = useCheckAccess(true);

  const deleteSingle = async (deleteUrl: string) => {
    if (!hasAccess) return noAccessPromise();
    return await api.delete(deleteUrl); // Single item delete
  };

  const deleteBatch = async (deleteUrl: string, ids: { id: string }[]) => {
    if (!hasAccess) return noAccessPromise();
    return await api.delete(deleteUrl, undefined, { data: ids }); // Batch delete with IDs
  };

  return useMutation({
    ...options,
    mutationFn:
      hasAccess && url
        ? isBatchDelete
          ? (ids: { id: string }[]) => deleteBatch(url, ids)
          : (ids: { id: string }[]) => deleteSingle(stringParse(url, [ids[0].id]))
        : () => noAccessPromise(),
  });
};

/**
 * @param get - The url to fetch data from
 * @param key - queryKey forwarded to useQuery
 * @param put - The url to put data to
 * @param post - The url to post data to
 * @param remove - The url to delete data from
 * @param revalidate - By default we cache data, setting this to true will always refetch the data
 * @returns an object of hooks { get, create, update, remove }
 */
export const useApi = (
  get: string,
  key: string,
  put: string = '',
  post: string = '',
  remove: string = '',
  revalidate: boolean = false
) => {
  return {
    get: useGet(get, key, revalidate),
    create: usePut(put),
    update: usePost(post),
    remove: useDelete(remove),
  };
};

interface UseAddItemBeforeSaveOptions<T> {
  queryKey: string | string[]; // The key for querying/updating the list in the queryClient
  defaultItem: T; // The default item to be inserted, of generic type T
}

export const useAddItemBeforeSave = <T,>({
  queryKey,
  defaultItem,
}: UseAddItemBeforeSaveOptions<T>) => {
  const queryClient = useQueryClient();

  const addItem = () => {
    const prevData = queryClient.getQueryData<PaginateResponse<T>>(
      Array.isArray(queryKey) ? queryKey : [queryKey]
    );

    if (!prevData) return;

    const newData = {
      ...prevData,
      data: [
        ...prevData.data,
        defaultItem, // Insert the default item (of generic type T)
      ],
    };

    queryClient.setQueryData(Array.isArray(queryKey) ? queryKey : [queryKey], newData);
  };

  return { addItem };
};
