import type { ChipProps as MuiChipProps, SxProps } from '@mui/material';
import { Chip as MuiChip } from '@mui/material';
import { colors } from 'styles/theme/colors';
import { X } from '@phosphor-icons/react';

export type ChipProps = Pick<
  MuiChipProps,
  'label' | 'onDelete' | 'id' | 'component' | 'variant'
> & {
  color?: string;
  white?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
  isEditable?: boolean;
};

export const Chip = ({
  label,
  onDelete,
  component = 'li',
  variant = 'filled',
  color = '',
  white = false,
  onClick,
  isEditable = true,
}: ChipProps) => {
  if (!label) return null;

  let sx: SxProps = {};
  if (color) {
    sx = {
      backgroundColor: color,
      color: white ? colors.white : colors.black,
      borderRadius: '6px',
    };
  }
  if (variant === 'outlined') {
    sx = { borderColor: color, color, fontWeight: 600, fill: 'transparent', borderRadius: '6px' };
  }

  return (
    <MuiChip
      component={component}
      onDelete={isEditable ? onDelete : undefined}
      label={label}
      variant={variant}
      sx={sx}
      deleteIcon={<X data-testid="CancelIcon" size={16} color={color} />}
      onClick={onClick}
    />
  );
};
