import { Avatar as MuiAvatar } from '@mui/material';
import type { AvatarProps } from './types';
import { useMemo } from 'react';

export const Avatar = ({ sx, size = 'lg', src, letters, alt }: AvatarProps) => {
  const sizeMap = {
    xs: 16,
    sm: 32,
    lg: 42,
  };

  const maxLetterMap = {
    xs: 1,
    sm: 1,
    lg: 2,
  };

  const sxStyles = useMemo(
    () => ({ width: sizeMap[size], height: sizeMap[size], ...sx }),
    [sx, size]
  );

  const slicedLetters = useMemo(
    () => letters?.slice(0, maxLetterMap[size]).toUpperCase(),
    [letters, size]
  );

  return (
    <MuiAvatar data-testid="avatar" sx={sxStyles} alt={alt} src={src}>
      {letters ? slicedLetters : null}
    </MuiAvatar>
  );
};
