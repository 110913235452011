import { Trans } from '@lingui/macro';
import { Typography, styled } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Upload } from '@phosphor-icons/react';
import type { FileInputProps } from '../types';
import { Button } from 'components/button';

export const FileInput = ({ displayAs = 'input', text, sx, options }: FileInputProps) => {
  const { getRootProps, getInputProps } = useDropzone({ ...options });

  if (displayAs === 'area') {
    return (
      <Area sx={sx} {...getRootProps()}>
        <input {...getInputProps()} />
        <Typography variant="bodyMedium">
          <Trans>{text}</Trans>
        </Typography>
      </Area>
    );
  }

  if (displayAs === 'input') {
    return (
      <div {...getRootProps()}>
        <FileButton sx={sx} variant="secondary" startIcon={<Upload size={24} />}>
          <Typography variant="bodyMedium">{text}</Typography>
          <input {...getInputProps()} />
        </FileButton>
      </div>
    );
  }

  return null;
};

const Area = styled('div')(({ theme }) => ({
  borderStyle: 'dashed',
  borderWidth: '1px',
  padding: theme.spacing(3),
}));

const FileButton = styled(Button)(({ theme }) => ({
  paddingBlock: theme.spacing(2),
  paddingInline: theme.spacing(3),
}));
