export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  brand: {
    50: '#E8F0EF',
    100: '#D0E1E0',
    200: '#A2C3C1',
    300: '#73A5A1',
    400: '#458782',
    500: '#176963',
    600: '#12544F',
    700: '#0E3F3B',
    800: '#092A28',
    900: '#051514',
  },
  error: {
    50: '#FBE8E9',
    100: '#F8D2D4',
    200: '#F0A5A9',
    300: '#E9787E',
    400: '#E14B53',
    500: '#DA1E28',
    600: '#AE1820',
    700: '#831218',
    800: '#570C10',
    900: '#2C0608',
  },
  warning: {
    50: '#FDF3E5',
    100: '#FCE7CC',
    200: '#F9CF99',
    300: '#F6B766',
    400: '#F39F33',
    500: '#F08700',
    600: '#C06C00',
    700: '#905100',
    800: '#603600',
    900: '#301B00',
  },
  success: {
    50: '#D9F0E1',
    100: '#D9F0E1',
    200: '#B4E0C2',
    300: '#90D1A5',
    400: '#79D09B',
    500: '#58C482',
    600: '#469D68',
    700: '#35764E',
    800: '#234E34',
    900: '#12271A',
  },
  grey: {
    0: '#FFFFFF',
    50: '#F2F2F3',
    100: '#E5E5E7',
    200: '#CBCCCE',
    300: '#B0B2B6',
    400: '#96999D',
    500: '#7C7F85',
    600: '#63666A',
    700: '#4A4C50',
    800: '#323335',
    900: '#19191B',
  },
  mintGreen: {
    50: '#F8FAFA',
    100: '#F1F5F5',
    200: '#E3EBEB',
    300: '#D5E2E2',
    400: '#C7D8D8',
    500: '#B9CECE',
    600: '#9BAEAE',
    700: '#7C8E8E',
    800: '#5E6F6F',
    900: '#3F4F4F',
  },
  marineBlue: {
    50: '#EEF0FF',
    100: '#DCE1FF',
    200: '#B9C3FF',
    300: '#97A4FF',
    400: '#7486FF',
    500: '#5168FF',
    600: '#4154D4',
    700: '#3140A9',
    800: '#202D7E',
    900: '#101953',
  },
  coral: {
    50: '#FDF3F3',
    100: '#FCE8E7',
    200: '#F8D1CF',
    300: '#F5B9B6',
    400: '#F1A29E',
    500: '#EE8B86',
    600: '#CC726D',
    700: '#AA5854',
    800: '#873F3B',
    900: '#652522',
  },
  pastelPink: {
    50: '#FEFAFA',
    100: '#FDF6F5',
    200: '#FAECEA',
    300: '#F8E3E0',
    400: '#F5D9D5',
    500: '#F3D0CB',
    600: '#E6B2AB',
    700: '#DA948B',
    800: '#CD776A',
    900: '#C1594A',
  },
  lavender: {
    50: '#FEFAFF',
    100: '#FEF6FE',
    200: '#FDECFE',
    300: '#FBE3FD',
    400: '#FAD9FD',
    500: '#F9D0FC',
    600: '#D8A8DB',
    700: '#B67FBA',
    800: '#955799',
    900: '#732E78',
  },
  pearl: {
    50: '#FEFDFE',
    100: '#FEFBFC',
    200: '#FCF7F9',
    300: '#FBF3F7',
    400: '#F9EFF4',
    500: '#F8EBF1',
    600: '#E7C7D6',
    700: '#D5A3BB',
    800: '#C4809F',
    900: '#B25C84',
  },
  sunflower: {
    50: '#FFFCF8',
    100: '#FFFAF0',
    200: '#FFF5E1',
    300: '#FFF0D3',
    400: '#FFEBC4',
    500: '#FFE6B5',
    600: '#E0C591',
    700: '#C1A46D',
    800: '#A18448',
    900: '#826324',
  },
  beige: {
    50: '#FEFEFD',
    100: '#FDFCFC',
    200: '#FCFAF9',
    300: '#FAF7F5',
    400: '#F9F5F2',
    500: '#F7F2EF',
    600: '#DAD0C9',
    700: '#BDAEA4',
    800: '#A18B7E',
    900: '#846959',
  },
};
