import styled from '@emotion/styled';
import { Check, Info as InfoIcon, ExclamationMark, X } from '@phosphor-icons/react';
import { capitalizeFirstLetter } from 'utils';
import { Button } from 'components';
import type { ToastProps, SnackbarAction } from 'features/notifications/types';
import { t } from '@lingui/macro';

export const Toast = ({
  id,
  variant = 'info',
  message,
  header = variant,
  actions = [{ id: 'close', label: t`Close` }],
  onClose,
}: ToastProps) => {
  const onClickButton = (action: SnackbarAction) => {
    if (action.onClick) {
      action.onClick(action.id);
      return;
    }
    if (onClose) {
      onClose(action.id, id);
    }
  };

  return (
    <Column data-testid="toast-container">
      <Row>
        <IconCircle>
          <Icon variant={variant} />
        </IconCircle>
        <Title>{capitalizeFirstLetter(header)}</Title>
      </Row>
      <Content>
        <Message>{message}</Message>
        {actions?.length ? (
          <Buttons color="white">
            {actions.map(action => (
              <Button key={action.id} onClick={() => onClickButton(action)}>
                {action.label}
              </Button>
            ))}
          </Buttons>
        ) : null}
      </Content>
    </Column>
  );
};

type IconProps = {
  variant: string;
  color?: string;
};

const Icon = ({ variant, color = 'white' }: IconProps) => {
  switch (variant) {
    case 'success':
      return <Check size={18} color={color} />;
    case 'info':
      return <InfoIcon size={18} color={color} />;
    case 'warning':
      return <ExclamationMark size={18} color={color} />;
    case 'error':
      return <X size={18} color={color} />;
    default:
      return null;
  }
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${p => p.theme.palette.text.primaryOnBrand};
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

const Message = styled.span`
  font-size: 16px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  button {
    color: ${p => p.color};
    font-weight: 600 !important;
    text-decoration: none;
    padding: 0 !important;
    white-space: nowrap;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 36px;
  gap: 24px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
