import { Trans } from '@lingui/macro';
import { Box, Paper, Typography, styled } from '@mui/material';
import { Button } from 'components';

type UIProps = {
  offlineReady: boolean;
  needRefresh: boolean;
  handleUpdate: (update: boolean) => void;
  handleClose: () => void;
};

export const Prompt = ({ offlineReady, needRefresh, handleUpdate, handleClose }: UIProps) => {
  return (
    (offlineReady || needRefresh) && (
      <Container>
        <Message>
          {offlineReady ? (
            <span>
              <Trans>App ready to work offline</Trans>
            </span>
          ) : (
            <span>
              <Trans>New content available, click on reload button to update.</Trans>
            </span>
          )}
        </Message>
        <ButtonContainer>
          {needRefresh && (
            <Button variant="primary" onClick={() => handleUpdate(true)}>
              <Trans>Reload</Trans>
            </Button>
          )}
          <Button variant="secondary" onClick={() => handleClose()}>
            <Trans>Close</Trans>
          </Button>
        </ButtonContainer>
      </Container>
    )
  );
};

const Container = styled(Paper)(
  ({ theme }) => `
  position: fixed;
  right: 0;
  bottom: 0;
  margin: ${theme.spacing(5)};
  padding: ${theme.spacing(5)};
`
);

const Message = styled(Typography)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(5)};
`
);

const ButtonContainer = styled(Box)(
  ({ theme }) => `
display: flex;
gap: ${theme.spacing(3)};
`
);
