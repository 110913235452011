import { ToastContainer } from 'features/notifications';

export const NotificationProvider = () => {
  return (
    <ToastContainer
      position="bottom-right"
      limit={10}
      autoClose={15000}
      closeButton={false}
      pauseOnFocusLoss={false}
    />
  );
};
