import { styled } from '@mui/material';
import { X } from '@phosphor-icons/react';
import { colors } from 'styles/theme/colors';
import { useNotification } from '../hooks';

export const CloseAll = () => {
  const { dismissNotification } = useNotification();

  return (
    <CloseAllButton onClick={() => dismissNotification()}>
      <X color="white" />
    </CloseAllButton>
  );
};

const CloseAllButton = styled('button')({
  position: 'absolute',
  bottom: 0,
  padding: 0,
  width: 32,
  border: 'none',
  minWidth: 32,
  aspectRatio: 1 / 1,
  left: 0,
  background: colors.grey[900],
  borderRadius: '50%',
  transform: 'translate(calc(-100% - 12px), calc(0% - 14px))',
  cursor: 'pointer',
  pointerEvents: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    background: colors.grey[800],
  },
});
