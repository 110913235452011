import { ToastContainer as Con } from 'react-toastify';
import styled from '@emotion/styled';
import 'react-toastify/dist/ReactToastify.min.css';

export const ToastContainer = styled(Con)`
  &&&.Toastify__toast-container {
    width: 515px;
    font-family: ${p => p.theme.typography.fontFamily};
    font-size: ${p => p.theme.typography.htmlFontSize}px;
  }
  .Toastify__toast {
    width: 507px;
    padding: 24px;
  }
  .Toastify__toast-body {
    padding: 0;
    margin: 0;
  }
  .Toastify__toast--info,
  .Toastify__toast--success,
  .Toastify__toast--warning,
  .Toastify__toast--error {
    color: ${p => p.theme.palette.text.primaryOnBrand};
    .Toastify__progress-bar {
      background-color: ${p => p.theme.palette.text.primaryOnBrand};
      opacity: 0.5;
    }
  }
  .Toastify__toast--success {
    background-color: ${p => p.theme.palette.fg.successPrimary};
    svg {
      fill: ${p => p.theme.palette.fg.successPrimary};
    }
  }
  .Toastify__toast--error {
    background-color: ${p => p.theme.palette.bg.errorSolid};
    svg {
      fill: ${p => p.theme.palette.bg.errorSolid};
    }
  }
  .Toastify__toast--warning {
    background-color: ${p => p.theme.palette.bg.warningSolid};
    svg {
      fill: ${p => p.theme.palette.bg.warningSolid};
    }
  }
  .Toastify__toast--info {
    background-color: ${p => p.theme.palette.bg.infoSolid};
    svg {
      fill: ${p => p.theme.palette.bg.infoSolid};
    }
  }
  .Toastify__toast-icon {
    display: none;
  }
`;
