import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes';
import {
  ThemeProvider,
  EasyPeasyProvider,
  I18nProvider,
  ReactQueryProvider,
  NotificationProvider,
  IconProvider,
} from 'providers';
import { CssBaseline } from '@mui/material';
import { GlobalStyles } from 'styles/globalStyles';
import { TaskBar } from 'features/modalManager';
import { MSW_LOGGING, MSW_UNHANDLED_REQUESTS } from 'config';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

async function enableMocking() {
  // If we are not on dev, render app normally
  // if (import.meta.env.MODE !== 'development') return;

  // Import server worker
  const { worker } = await import('mocks/browser');

  // `worker.start()` returns a Promise that resolves
  // To avoid race conditions, render app after promise is resolved
  return worker.start({
    onUnhandledRequest: MSW_UNHANDLED_REQUESTS,
    quiet: MSW_LOGGING,
    waitUntilReady: true,
  });
}

enableMocking().then(() => {
  root.render(
    <StrictMode>
      <I18nProvider>
        <ReactQueryProvider>
          <EasyPeasyProvider>
            <ThemeProvider>
              <IconProvider>
                <TaskBar />
                <CssBaseline />
                <GlobalStyles />
                <NotificationProvider />
                <RouterProvider router={router} />
              </IconProvider>
            </ThemeProvider>
          </EasyPeasyProvider>
        </ReactQueryProvider>
      </I18nProvider>
    </StrictMode>
  );
});
